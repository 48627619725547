@import '../../assets/sass/styles.scss';

.Winners {
  margin: 8rem auto;
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: $fazer;
    h2 {
      font-family: FazerHeadline;
      font-size: 3rem;
    }
    .winners-wrapper {
      ul {
        padding: 0px 30px;
        font-weight: 500;
        text-align: center;
        &.hidden {
          //   display: none;
        }
      }
      .prize-name {
        font-family: Fazer;
        font-size: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Winners {
    .container {
      .winners-wrapper {
        ul {
          padding: 0px 30px;
          font-weight: 500;
        }
        .prize-name {
          font-size: 1.6rem;
          text-align: center;
        }
      }
      span {
        text-align: center;
      }
    }
  }
}
