@import '../../assets/sass/styles.scss';

.Register {
  text-align: start;
  input {
    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.9rem;
    }
  }
  #rules-check {
    margin-right: 10px;
  }
  .btn {
    color: white;
    background-color: $fazer;
    border: none;
    border-radius: 30px;
    font-weight: 500;
    font-size: 1.1rem;
    font-family: Fazer;
    padding: 15px 50px;
    cursor: pointer;
    margin-bottom: 1.5rem;
    margin-top: 20px;
    &:hover {
      filter: saturate(70%);
    }
  }
  .campaign-over-wrapper {
    text-align: center;
    margin: 1rem auto;
    color: $fazer;
    font-size: 1.2rem;
    span {
      display: block;
    }
  }
}

@media (max-width: 992px) {
}
