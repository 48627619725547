@import '../../assets/sass/styles.scss';

.Rules {
  width: 100vw;
  // height: 100vh;
  // position: absolute;
  display: flex;
  align-items: center;
  // overflow-y:auto;
  flex-direction: column;
  text-align: start;
  div {
    max-width: 65vw;
  }
  h2 {
    text-align: start;
    color: $primary;
    font-size: 2rem;
    font-weight: 500;
    margin: 5vh 0 3vh 0;
  }
  a {
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
  }
  .close-button {
    position: absolute;
    top: 1px;
    right: 50px;
    font-size: 5rem;
    line-height: 3rem;
    font-weight: 200;
    &:hover {
      text-decoration: none;
      transform: scale(1.05);
    }
  }
}

ol {
  list-style: none;
  counter-reset: item;
  > li {
    counter-increment: item;
    display: table;
    &:before {
      padding-right: 10px;
      content: counters(item, '.') ' ';
      display: table-cell;
    }
  }
  li {
    margin: 10px 0;
  }
  ol {
    padding-left: 20px;
  }
  // ul li {
  //   margin-left: 20px;
  // }
  .bold {
    font-weight: 600;
  }
  table {
    border-collapse: collapse;
    td,
    th {
      padding: 3px;
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
}

@media screen and (max-width: 769px) {
  .Rules {
    height: auto;
    position: static;
    padding-top: 10vh;
    div {
      max-width: 90vw;
    }

    .close-button {
      right: 20px;
    }
  }

  ol {
    list-style: none;
    counter-reset: item;
    ol {
      padding-left: 10px;
    }
    ul li {
      margin-left: 10px;
    }
  }
}
