@import '../../assets/sass/styles.scss';
.Main {
  .header {
    padding: 0 5rem;
    .columns {
      &:last-child {
        margin-bottom: auto;
      }
      &.language-row {
        background-color: $primary;
        color: white;
        text-align: end;
        .language-button {
          background: none;
          border: none;
          color: white;
          cursor: pointer;
          font-weight: 500;
          font-size: 1.2rem;
          padding: 20px 8rem;
          &:hover {
            // text-decoration: underline;
            transform: scale(1.05);
          }
        }
      }
      &.top-row {
        min-height: 500px;
        background-color: $primary;
        color: white;
      }
      &.bottom-row {
        min-height: 200px;
        color: $fazer;
        ul {
          margin: 1rem auto;
        }
      }
      .column {
        min-height: 100%;
        &.logo-column {
          text-align: end;
          img {
            margin: auto 2rem;
          }
        }
        &.campaign-header-column {
          text-align: start;
          h1 {
            line-height: 1;
            font-size: 5rem;
            font-family: FazerHeadline;
            margin-bottom: 1rem;
          }
          p {
            &:first-of-type {
              margin-bottom: 2rem;
            }
            &:last-child {
              font-size: 1.2rem;
            }
          }
          a {
            color: white;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
          p,
          a {
            font-size: 1.5rem;
          }
        }
        &.prize-column {
          position: relative;
          .prize-child {
            position: absolute;
            bottom: 0;
            left: 0;
            .prizes {
              max-width: 550px;
            }
          }
        }
      }
    }
  }
  .register-form {
    .columns {
      &:first-child {
        margin-top: auto;
      }
    }
  }
  .products {
    margin-top: 4rem;
    .columns {
      // justify-content: center;
      &.heading-columns {
        text-align: start;
        color: $primary;
        font-weight: 500;
        font-size: 2rem;
        font-family: Fazer;
        margin-bottom: 2rem;
      }
      img {
        max-width: 22vw;
      }
      .column {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Main {
    .header {
      padding: 0 2rem;
      .columns {
        &.language-row {
          margin-bottom: -1px;
          .language-button {
            padding: 20px 1rem;
          }
        }
        &.bottom-row {
          min-height: 120px;
          .prize-column {
            padding: 0;
          }
        }
        .column {
          .second-prize {
            max-width: 200px;
            margin-bottom: -25px;
            margin-right: -40px;
          }
          &.logo-column {
            text-align: center;
            img {
              margin: auto;
            }
          }
          &.campaign-header-column {
            h1 {
              font-size: 4rem;
            }
            p,
            a {
              font-size: 1.2rem;
            }
            p {
              &:last-child {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
    .register-form {
      margin: auto 2rem;
      .columns {
        .column {
          &.is-3 {
            padding: 0;
          }
        }
      }
    }

    .products {
      margin: 2rem 2rem auto 2rem;
      .columns {
        // justify-content: center;
        &.heading-columns {
          font-size: 1.5rem;
        }
        img {
          max-width: 45vw;
        }
      }
    }
  }
}
