@font-face {
  font-family: 'Fazer';
  src: url('../fonts/FazerText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fazer';
  src: url('../fonts/FazerText-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FazerHeadline';
  src: url('../fonts/FazerHeadline-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
